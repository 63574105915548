import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {delUserData} from '../../reduxconfig/UserSlice';
import { FaChartArea, FaEnvelopeSquare, FaHome, FaSignOutAlt, FaSnapchat, FaTable, FaTabletAlt, FaUser, FaWrench } from "react-icons/fa"; 
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { FaUserTie } from 'react-icons/fa6';

export default function ClientSideNav() 
{
  const loginUserRole = useSelector((store) => store.user.value.role);
  useEffect(()=>{
    window.sideBarTog()
  },[])

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = ()=>
  {    
      toast.success('Thanks , See You Soon !')
      dispatch(delUserData())
      navigate("/user/login")
  }

  return (
    <>
      <ul
        className=" navbar-nav  sidebar sidebar-dark accordion pt-2"
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}
        <div
          className="mt-3 sidebar-brand d-flex align-items-center justify-content-center" style={{mixBlendMode:"luminosity"}}
        >
          <img src="/images/logo.png" className='rounded'  alt="logoimg"/>
        </div>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-1" />

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />       

        {/* <!-- Nav Item - Pages Collapse Menu --> */}
        <li className="nav-item">
          <Link className="nav-link" to={`/${loginUserRole}/dashboard`}>
              <FaHome/>
              <span className='ml-2'>Dashboard</span>
          </Link>
        </li>
        
        <li className="nav-item">
          <Link className="nav-link" to="/client/brokers">
          <FaUserTie/>
            <span className='ml-2'>Brokers</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/admin/messages">
          <FaWrench/>
            <span className='ml-2'>Strategies</span>
          </Link>
        </li>


        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        <li className="nav-item">
          <b className="nav-link" style={{cursor:'pointer'}} onClick={logout}>
            <FaSignOutAlt/>
            <span className='ml-2'>LogOut</span>
          </b>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider d-none d-md-block" />

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
      </ul>
    </>
  );
}
