import blazeConnection from 'iifl-blaze'

function iiflConnect(credentials)
{
   return new Promise((resolve,reject)=>
    {
        blazeConnection.connect(credentials.appkey,credentials.secretkey)
        .then(res=>resolve(res))
        .catch(res=>reject(res))
    })
}

const connectWithBroker = (brokerName)=>
{
    console.log(brokerName)
    var cnn = undefined;
    switch(brokerName)
    {
        case 'IIFL' : cnn = iiflConnect; break;
    }
    return cnn;
}

export default connectWithBroker;