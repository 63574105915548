const SERVER = process.env.REACT_APP_ENV=='dev'?process.env.REACT_APP_SERVER_DEV:process.env.REACT_APP_SERVER_PROD;

export const URLS = {
    REGISTER : `${SERVER}/profitology/register`,
    LOGIN : `${SERVER}/profitology/login`,
    LIST_MASTERBROKER : `${SERVER}/profitology/listmasterbrokers`,
    RESET_TOKEN : `${SERVER}/auth/resettoken`,
    LOAD_MSGS : `${SERVER}/auth/subadmin/listcontact`,
    SAVE_MSGS : `${SERVER}/profitology/savecontact`,

    SAVE_MANAGER : `${SERVER}/auth/admin/savemanager`,
    LIST_USERS : `${SERVER}/auth/subadmin/listusers`,
    STATUS_CHANGE:`${SERVER}/auth/subadmin/changeuserstatus`,
    CHANGE_BLOCK_STATUS:`${SERVER}/auth/admin/changeblock`,
    LIST_BLOCK_USERS:`${SERVER}/auth/admin/listblockusers`,

    SAVE_BROKER : `${SERVER}/auth/client/savebroker`,
    LIST_CLIENT_BROKERS : `${SERVER}/auth/client/mybrokers`,
    SET_DEFAULT_BROKER : `${SERVER}/auth/client/setdefaultbroker`,
    GET_DEFAULT_BROKER : `${SERVER}/auth/client/defaultbroker`,
}
class ApiCallService
{
    getCall(url)
    {
        return new Promise((resolve,reject)=>{
            fetch(url)
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }
    postCall(url,data)
    {
        console.log(process.env.NODE_ENV , url)
        return new Promise((resolve,reject)=>{
            fetch(url,{
                method : "POST",
                headers:{
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }

    getCallWithToken(url,token)
    {
        return new Promise((resolve,reject)=>{
            fetch(url,{
                headers:{
                    'Authorization' : 'Bearer '+token
                }
            })
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }
    postCallWithToken(url,token,data)
    {
        return new Promise((resolve,reject)=>{
            fetch(url,{
                method : "POST",
                headers:{
                    'content-type': 'application/json',
                    'Authorization' : 'Bearer '+token
                },
                body: JSON.stringify(data)
            })
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }
    patchCallWithToken(url,token)
    {
        return new Promise((resolve,reject)=>{
            fetch(url,{
                method : "PATCH",
                headers:{
                    'content-type': 'application/json',
                    'Authorization' : 'Bearer '+token
                }
            })
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }
}

export default new ApiCallService();
