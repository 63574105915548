const  axios =  require("axios");
const  urls = require("./urls");

class User
{
    constructor(userID,token)
    {
        this.userID = userID;
        this.token = token;
    }
    async logout()
    {
        const resp = await axios.delete(urls.LOGOUT,{
            headers:{
                "Authorization" : this.token
            }
        });
        return resp.data;
    }
}

module.exports = User