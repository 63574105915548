import { LOGIN } from "./urls";
import User from "./User";

class Connection
{
    connect(appKey,secretKey)
    {
        return new Promise((resolve,reject)=>
        {
            const source =  "WebAPI";
            const args = {secretKey,appKey,source};
            fetch(LOGIN,{
                method : 'POST',
                headers: {
                    'Content-Type' : 'application/JSON'
                },
                body : JSON.stringify(args)
            }).then(res=>res.json())
            .then(data=>
            {                
                //console.log(data)
                if(data.type=='success')
                {
                 const user = new User(data.userID,data.token);
                 resolve({status:true,user})
                }else{
                    reject({status:false,err})
                }
            })
            .catch(err=>{
                reject({status:false,err})
            });   
        })        
    }
}

export default new Connection();
