import { FaPlus } from 'react-icons/fa'
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useEffect, useState } from "react";
import './Broker.css'
import AddBrokerForm from './AddBrokerForm'
import { useSelector } from 'react-redux';
import urls from 'iifl-blaze/src/iifl/urls';
import toast from 'react-hot-toast';
export default function Broker()
{
    const [mbrokers, setMBrokerList] = useState([]);
    const [brokerlist, setBrokerList] = useState([]);
    const token = useSelector((store) => store.user.value.token); 
 
 
    const [isAdd,setIsAdd] = useState(false)
    
    useEffect(() =>{ loadMBroker();loadBrokers();}, []);
    const loadMBroker = () => {
      apiCall.getCall(URLS.LIST_MASTERBROKER).then((res) => {
        if (res.status) {
          setMBrokerList(res.data);
        }
      });
    };
    const loadBrokers = async () => {    
        const resp = await apiCall.getCallWithToken(URLS.LIST_CLIENT_BROKERS, token);
        setBrokerList(resp.data);
    };
    
    const addNewBroker = (broker)=>setBrokerList([...brokerlist,broker]);

    const setDefault = (id)=>
    {
        const url = `${URLS.SET_DEFAULT_BROKER}/${id}`
        apiCall.patchCallWithToken(url,token)
        .then(result=>{
            console.log(result)
            if(result.status){
                setBrokerList(brokerlist.map(b=>{
                    return b.id==id?{...b,isdefault:true}:{...b,isdefault:false}
                }))
                toast.success(result.msg)
            }else{
                toast.error(result.msg)
            }
        })
    }
    return <>
        <div className="container-fluid Broker">
            <div className="row mt-3">
                <div className='col-xl-10 col-lg-10'>
                <h3 className="managerHeadText">Brokers</h3>
                </div>
                <div className='col-xl-2 col-lg-2 text-center'>
                    <button onClick={()=>setIsAdd(true)} className='btn btn-success'><FaPlus/>
                    &nbsp;&nbsp;
                    Add</button>
                </div>
            </div>
            {isAdd?<AddBrokerForm addNewBroker={addNewBroker} mbrokers={mbrokers} setIsAdd={setIsAdd}/>:""}
            <hr/>

            <table className='table'>
                <thead>
                    <tr>
                        <th>S. No.</th>
                        <th>Broker Name</th>
                        <th>App Name</th>
                        <th>Default</th>
                    </tr>
                </thead>
                <tbody>
                    {brokerlist.map((ob,index)=><tr>
                        <td>{index+1}</td>
                        <td>{ob.name}</td>
                        <td>{JSON.parse(ob.details).appname}</td>
                        <td>
                            {ob.isdefault==false?<button 
                            onClick={()=>setDefault(ob.id)} className='btn-sm btn-success'>Set Default</button>:<b className='text-danger'>
                                Default Broker
                            </b>}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </> 
}