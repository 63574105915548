const blazeConnection = require('./iifl/Connection').default;
//const User =  require('./iifl/User');

module.exports = blazeConnection

// function test()
// {
//     const d = blazeConnection.connect(new ConnArgs("Tgyx350$1b","b605d581a35c9b8be84829","WebAPI"))
//     d.then((user)=>
//     {
//         console.log(user)
//         // user.logout().then((res:any)=>console.log(res))
//     })
//     .catch((dt)=>console.log(dt.response.data))
// }
// test()
