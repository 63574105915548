import toast from "react-hot-toast";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import connectWithBroker from '../../../brokersconfig/init'

export default function AddBrokerForm({setIsAdd,mbrokers,addNewBroker}) 
{
  const token = useSelector((store) => store.user.value.token); 
  const [selectbroker, setSelectBroker] = useState(undefined);
  
  const handleChange = (event) => 
  {
    const name = event.target.name;
    const value = event.target.value;

    var formdata = { ...selectbroker.formdata };
    formdata[name] = value;
    setSelectBroker({ ...selectbroker, formdata });
  };

  const save = (event) => 
  {
    event.preventDefault();

    const brokerCnn = connectWithBroker(selectbroker.name)

    //console.log(selectbroker)
    //console.log(typeof brokerCnn)

    toast.promise(brokerCnn(selectbroker.formdata),{
      msg : "Broker Saving ..",
      success : result=>
      { 
        console.log(result)
        if(result.status)
        {
          apiCall.postCallWithToken(URLS.SAVE_BROKER,token,selectbroker)
          .then(result=>{ 
            toast.success(result.msg);
            addNewBroker(result.data);
            event.target.reset()
          })
          .catch(result=>{ toast.error(result.msg); })
        }else{
          toast.error(result.err);
        }
      },
      error : result=>{ 
        console.log(result)
        toast.error("Broker Connection Failed !"); }
    })
  };

  return <><form onSubmit={save}><div className="row">
  <h4>Choose Broker</h4>
  {mbrokers.map(ob=><div className='col-xl-1 col-lg-1 text-center'>
      <img onClick={()=>setSelectBroker({name:ob.name,brokerui:JSON.parse(ob.uidetails),formdata:{}})} src={`/images/${ob.image}`} className='brokerlogo'/>
  </div>)}                
</div>
{selectbroker!=undefined?<>
  <div className='row mt-3'>
  {Object.keys(selectbroker?.brokerui).map(key=><div className='col-xl-4 col-lg-4'>
      <input className='form-control' type={selectbroker.brokerui[key].type} placeholder={selectbroker.brokerui[key].title}  onChange={handleChange} name={key} required/>
  </div>     
  )}
</div>
<div className='row mt-3'>
  <div className='col-xl-2 col-lg-2'>
      <button className='btn btn-success' disabled={selectbroker==undefined}>Add Broker</button>
  </div>
  <div className='col-xl-2 col-lg-2'>
      <button onClick={()=>{setIsAdd(false);setSelectBroker(undefined)}}  className='btn btn-primary'>Close</button>
  </div>
</div>
</>:""}

</form></>
}
